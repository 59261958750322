$(document).ready(() => {
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  function checkTouchDevice() {
    return "ontouchstart" in document.documentElement;
  }

  if (checkTouchDevice()) {
    document.querySelector('html').setAttribute('ontouchmove', '')
  }

  require("./target-blank.js");
  require("./menu-button.js");
  require("./validation.js");
  require("./access.js");
  require("./scroll2id.js");
  require("./grid-hover.js");
});

// remove preloader
$(window).on("load", () => {
  $(".preloader").fadeOut(() => {
    require("./animations.js");
  });
});

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
      }
  };
}