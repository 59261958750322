(function() {
    let items = document.querySelectorAll('.block-grid__item')
    let circles = document.getElementsByClassName('block-grid__circle')
    let lines = document.getElementsByClassName('block-grid__line')

    items.forEach(function(item) {
      item.addEventListener('mouseover', function(e) {
        eventHover(item)
      })
      item.addEventListener('mouseout', function(e) {
        eventOut(item)
      })
    })


    function eventHover(item) {
      let posNumber = item.getAttribute('data-item')
      for (let i = 0; i < posNumber; i++) {
        circles[i].classList.add('active')
      }
      for (let i = 0; i < posNumber - 1; i++) {
        lines[i].classList.add('active')
      }
    }
    function eventOut(item) {
      for (let i = 0; i < circles.length; i++) {
        circles[i].classList.remove('active')
      }
      for (let i = 0; i < lines.length; i++) {
        lines[i].classList.remove('active')
      }
    }
})()