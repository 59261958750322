// // start Input Mask---------------------------------------------------------------------------------
// var input = document.querySelector(".main-form__item>input[type=tel]");
// // let phoneNumber = window.intlTelInput(input, {
// //   autoPlaceholder: "aggressive",
// //   separateDialCode: true,
// //   customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
// //     return `${selectedCountryPlaceholder}*`;
// //   },
// // });

// // $(input).on('close:countrydropdown', function(e){
// //   elon(this)
// // });

// // let elon = function(e) {
// //   let phldr = $(e).attr('placeholder')
// //   phldr = phldr.replace('*', '')
// //   var currentMask = phldr.replace(/[0-9+]/ig,'9');
// //   $(e).attr('placeholder', `${currentMask}*`);
// //   $(input).inputmask({ mask: currentMask, keepStatic: true });
// // }

// // elon(input)

// var im = new Inputmask("(999) 999-9999");
// im.mask(input);
// // end Input Mask-----------------------------------------------------------------------------------

let form = document.querySelector('#contact-form')
let inputs = form.querySelectorAll('input')


$(form).submit(function(e) {
  e.preventDefault();
  removeMessageBox()
  validation()

  let alerts = document.querySelectorAll('.alert-text')
  if (alerts.length == 0) {
    // let cc = form.querySelector('.iti__selected-dial-code').textContent
    // let ccInput = form.querySelector('input[name="cc"]')
    // ccInput.value = cc
  
    $.ajax({
      url: 'contact.php',
      type: 'POST',
      contentType: false,
      processData: false,
      data: new FormData(this),
      success: function (msg) {
        if (msg == 'ok') {
          $('#contact-form').trigger('reset');
          messageBox(form, 'Your message has been sent. Thank you!')
          let alert = document.querySelector('.alert-text')
          alert.style.color = "#1E63A9"
          alert.style.fontSize = "18px"
        } else {
          messageBox(form, 'Something wrong...')
        }
      }
    });
  }
});

let validation = function() {
  let inputs = form.querySelectorAll('input')
  let selects = form.querySelectorAll('select')
  inputs.forEach(function(input) {
    if (input.getAttribute('req') && input.value == '') {
      messageBox(input.parentNode, '*Required field')
    } else if (input.getAttribute('req') && input.name == 'email') {
      emailValidation(input)
    } else if (input.getAttribute('req') && input.name == 'tel') {
      phoneValidation(input)
    }
  })
  selects.forEach(function(select) {
    if (select.getAttribute('req') && select.value == '') {
      messageBox(select.parentNode, '*Required field')
    }
  })
}

let emailValidation = function (item) {
  let text = '*Please enter a valid email address'
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item.value)) {
    return true
  } else {
    let position = item.parentNode
    messageBox(position, text)
    return false
  }
}

let phoneValidation = function(item) {
  let text = '*Please enter a valid phone number'
  if (item.value.match(/_/) == null) {
    return true
  } else {
    let position = item.parentNode
    messageBox(position, text)
    return false
  }
}

let messageBox = function (position, text) {
  const message = document.createElement('p')
  message.classList.add('alert-text')
  message.textContent = text
  position.appendChild(message)
}

let removeMessageBox = function () {
  let alerts = document.querySelectorAll('.alert-text')
  alerts.forEach(function(alert) {
    alert.remove()
  })
}