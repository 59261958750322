(function() {
  let button = document.querySelector('.menu-button')
  let menu = document.querySelector('.page-header__menu')
  button.addEventListener('click', function() {
    if(menu.classList.contains('active')) {
      closeMenu()
    } else {
      menu.classList.add('active')
      button.classList.add('active')
    }
  })

  let closeMenu = function() {
    menu.classList.remove('active')
    button.classList.remove('active')
  }

  window.addEventListener('resize', function() {
    if (window.innerWidth > 992) {
      closeMenu()
    }
  })
})()