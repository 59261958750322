(function() {
  let block = document.querySelector('.full-size-block')
  if (window.innerWidth < 768) {
    document.querySelector('body').classList.add('bodylock');
  }
  if (block) {
    block.addEventListener('click', function() {
      block.classList.add('hide')
      document.querySelector('body').classList.remove('bodylock')
    })
  }
})()